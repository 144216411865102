import {environment as rivalEnvironment} from "./environment.rival";

export const environment = {
  ...rivalEnvironment,

  rivalName: "supremeplay",
  casinoId: 56,
  GA_TRACKING:'G-SEN9MWBN8P',

  rivalChatGroupName: "Supreme play",
  prettyName: "Supremeplay Casino",
  contactEmail: "support@supremeplay.com",
  docsEmail: "documents@supremeplay.email",

  //SEO
  metaDescription: "Join Supreme Play Casino for real Cash bonuses! Over 400+ games, including slots and live table dealer gambling. Secure gaming,fast payouts and smooth transactions even in bitcoin. Play anytime, hit the jackpot on our mobile slot machines!",
  logoName: "supremeplay-casino-online-casino-logo.png",
  logoAltText: "SupremePlay Casino online logo with bold green and silver text ideal for slots poker and live dealer games with top casino bonuses."
};


